import { Box, Progress, Text } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";
import { useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { AuthContext } from "contexts/AuthContext";
import TimelineItem from "components/dataDisplay/TimelineItem.js";
const VideoProgressBar = ({ video, id, status, updateMessage, messageId }) => {
  const authData = useContext(AuthContext);

  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [progressInPercentage, setProgressOutPercentage] = useState(0);

  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    // console.log("pusher init", JSON.stringify(video));
    let progressStatuses = video?.progressStatuses ?? [];
    let sizeOfStatus = progressStatuses.length;
    setCurrentStatus(progressStatuses[sizeOfStatus - 1]);
    setProgressOutPercentage((sizeOfStatus / 10) * 100);
  }, []);

  useEffect(() => {
    // console.log("pusher message",messageId,video)
    let listeningEvent = `video-status-update-${id}`;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });
    var channel = pusher.subscribe(`video-generation-${id}`);
    // console.log(`pusher registered listener`, id);
    channel.bind(listeningEvent, function (data) {
      // console.log("pusher notif", JSON.stringify(data));
      if (data?.videoType === "REPLICATE") {
        let progressStatuses = ["Done"];
        let sizeOfStatus = progressStatuses.length;
        setCurrentStatus(progressStatuses[sizeOfStatus - 1]);
        setProgressOutPercentage((sizeOfStatus / 1) * 100);
        updateMessage(messageId, {
          ...data,
          type: "replicateVideo",
        });

        authData.profileFetch();
        return;
      } else {
        let progressStatuses = data?.progressStatuses ?? [];
        let sizeOfStatus = progressStatuses.length;
        setCurrentStatus(progressStatuses[sizeOfStatus - 1]);
        setProgressOutPercentage((sizeOfStatus / 10) * 100);
        updateMessage(messageId, {
          ...data,
          type: "shortGpt",
        });
      }

      authData.profileFetch();
    });
    // Cleanup
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [id]);

  const getMessageByStep = (step) => {
    if (step === "_generateTempAudio") {
      return isKor ? "오디오 생성 중" : "Generating audio…";
    } else if (step === "_speedUpAudio") {
      return isKor ? "오디오 조정 중" : "Adjusting length and speed…";
    } else if (step === "_timeCaptions") {
      return isKor ? "동영상 캡션 생성 중" : "Generating captions…";
    } else if (step === "_generateVideoSearchTerms") {
      return isKor ? "동영상 생성 중" : "Generating video…";
    } else if (step === "_generateVideoUrls") {
      return isKor ? "동영상 생성 중" : "Generating video…";
    } else if (step === "_chooseBackgroundMusic") {
      return isKor ? "배경 음악 추가 중" : "Adding background music…";
    } else if (step === "_prepareBackgroundAssets") {
      return isKor ? "동영상 준비 중" : "Preparing assets…";
    } else if (step === "_prepareCustomAssets") {
      return isKor ? "동영상 준비 중" : "Preparing assets…";
    } else if (step === "_editAndRenderShort") {
      return isKor ? "동영상 렌더링 중" : "Rendering…";
    } else if (step === "_addMetadata") {
      return isKor ? "오마무리 중" : "Finalizing…";
    } else {
      return isKor ? "준비 중" : "Preparing…";
    }

    return step;
  };

  return (
    <>
      <Box
        width={"100%"}
        paddingX={"15%"}
        paddingY={{ base: "80px" }}
        // border={"1px solid #ddd"}
      >
        <Box
          border={"1px solid #ddd"}
          padding={"10px"}
          borderRadius={"10px"}
          marginY={"10px"}
        >
          <Progress
            hasStripe={status === "failed"}
            // colorScheme={"red"}
            // value={30}
            value={progressInPercentage}
            isAnimated={true}
            width={"100%"}
            borderRadius={"0px"}
          />
        </Box>
        {status !== "failed" ? (
          <>
            <Text
              align={"left"}
              fontSize="14px"
              fontWeight="500"
              fontFamily="'Noto Sans', sans-serif"
            >
              Processing video...
            </Text>
            <Box
              display={"flex"}
              flexWrap={"nowrap"}
              gap={"5px"}
              fontSize="14px"
              marginTop={"3px"}
            >
              <>
                <Text fontFamily="'Noto Sans', sans-serif">
                  {progressInPercentage}%{" "}
                </Text>
                <Text color={"#A3AED0"} fontFamily="'Noto Sans', sans-serif">
                  {/* Generating... */}
                  {getMessageByStep(currentStatus)}
                </Text>
              </>
            </Box>
          </>
        ) : (
          <Text textAlign={"center"}>
            Video Production was unsuccessful. Although this is rare, we are
            aware of this issue and working to improve our systems already.
          </Text>
        )}
      </Box>
    </>
  );
};

export default VideoProgressBar;
