/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */

import {
  Box,
  SkeletonText,
  Text,
  useDisclosure,
  Flex,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

// Assets
import axios from "axios";
import ChatPagePrompt2 from "components/new/ChatPagePrompt2";
import { userPrivateRequest, userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { SessionListContext } from "contexts/SessionListContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory, useLocation, Link } from "react-router-dom";
import { uniq } from "underscore";
import urlParser from "url";
import { getGoogleData } from "utils/GoogleSearchEngine";
import { formatReferenceText } from "utils/MessageService";
import MessageRow from "./MessageRow";
import AssistantModal from "layouts/HomeNew/Search/AssistantModal";
import { Buffer } from "buffer";
import ShareModal from "components/new/ShareModal";
import GetVideoConfirmation from "components/new/GetVideoConfirmation";
import { FiX, FiGlobe } from "react-icons/fi";
import Banner from "views/admin/nfts/marketplace/components/Banner";
import FirstModal from "../../../../../../layouts/HomeNew/Search/FirstModal";
import PushNotification from "utils/PushNotification";
import Cookies from "js-cookie";
import FloatingMessageBar from "./FloatingMessageBar";
import { ModalContext } from "contexts/ModalContext";
import { hasModuleScopeEligibility } from "utils/utils";
import DepositButton from "views/components/DepositButton";

export default function Messages2(props) {
  const { status, onOpen, isOpen, isTutorial, name, ...rest } = props;
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  function extractSessionParameter(url) {
    const regex = /[?&]sharedSession=([^&]+)/;
    const match = url.match(regex);

    if (match && match.length > 1) {
      return match[1];
    }

    return "";
  }
  const router = useCustomHistory();
  const resultRef = useRef();
  const { t } = useTranslation();
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [recording, setRecording] = useState(false);
  const [initialFetch, setInitialFetch] = useState(true);

  let [result, setResult] = useState("");
  let [isSearchShow, setIsSearchShow] = useState(true);
  let [criteria, setCriteria] = useState("text");
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [pagiTaskQueue, setPagiTaskQueue] = useState([]);
  const [isPagiCall, setPagiCall] = useState(false);
  const [executingshareReward, setExecutingshareReward] = useState(false);
  const [micModal, setMicModal] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  const [auto, setAuto] = useState(false);
  const [orientation, setOrientation] = useState("landscape");
  const [includeCaption, setIncludeCaption] = useState("no");
  const [language, setLanguage] = useState("English");
  const [voice, setVoice] = useState("alloy");
  const [homepageModalClose, setHomepageModalClose] = useState(false);
  const [isScrollLocked, setScrollLocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  const [assistModal, setAssistModal] = useState(null);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const responseProgressRef = useRef();
  const partiallyStoppedRef = useRef();
  const sessionIdRef = useRef(null);
  const isPagiExecuting = useRef(false);
  const timeoutExecuted = useRef(false);
  const history = useCustomHistory();
  const { search } = useLocation();
  const platform = process.env?.REACT_APP_PLATFORM;

  const authData = useContext(AuthContext);

  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const sessionListProvider = useContext(SessionListContext);
  const COLORS = configData?.config?.global?.COLORS?.value;
  const modalData = useContext(ModalContext);

  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const secondaryColor = useColorModeValue(
    COLORS.secondaryColor,
    COLORS.secondaryDarkColor
  );
  const bgColor = useColorModeValue(COLORS.bgColor, COLORS.bgDarkColor);

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";
  const {
    selectedPrompt,
    setSelectedPrompt,
    currentSessionInfo,
    setCurrentSessionInfo,
    setCurrentSessionId,
    currentSessionId,
    selectedVoice,
    setSelectedVoice,
  } = sessionListProvider;
  let sessionList = sessionListProvider.records || {};
  const maxQuestionChar = config[membership]?.maxQuestionChar || 100;
  const numberOfReferenceInPrompt =
    config[membership]?.numberOfReferenceInPrompt || 3;

  let currentLanguage = window.location.pathname.split("/")[1] || "en";
  let chatPagePlaceholder = isKor
    ? config?.global?.CHAT_PAGE_CONFIG?.value?.messageBoxPlaceholderKr
    : config?.global?.CHAT_PAGE_CONFIG?.value?.messageBoxPlaceholder;
  const VIDEO_CONFIGURATION =
    configData?.config?.global?.VIDEO_CONFIGURATION?.value || {};

  let queryDataV =
    urlParser.parse(window.location.href, { parseQueryString: true }).query ||
    {};
  let queryvalueV = queryDataV.mode || "";

  const handleMicModal = useCallback(() => {
    setMicModal(true);
    setAuto(true);
  }, []);

  useEffect(() => {
    if (selectedPrompt?._id) {
      setInputPlaceholder(
        recording
          ? t("Recording")
          : selectedPrompt?.placeholder
          ? selectedPrompt?.placeholder
          : chatPagePlaceholder
      );
    } else {
      setInputPlaceholder(recording ? t("Recording") : chatPagePlaceholder);
    }
  }, [selectedPrompt, chatPagePlaceholder]);

  const handleClick = () => {
    sessionChanged(null);
  };
  const executePagi = async (string) => {
    // string = Buffer.from(string, "base64").toString("ascii");
    // // console.log(string, 'string string string')
    let pagiTask = JSON.parse(string);

    const promptInfo = await userPublicRequest
      .get(`/get-prompt/${pagiTask.task.id}`)
      .then((response) => {
        return response?.data?.prompt ?? null;
      })
      .catch((error) => {
        console.error(error.message);
        return [];
      });

    isPagiExecuting.current = true;
    setSelectedPrompt({ ...promptInfo, pagiTask });
    if (
      promptInfo?.allowVideoCreate === "BOTH" ||
      promptInfo?.allowVideoCreate === "SHORTGPT"
    ) {
      timeoutExecuted.current = true;
    }
  };

  useEffect(() => {
    if (
      Object.keys(selectedPrompt).length > 0 &&
      selectedPrompt?.pagiTask?.task?.prompt &&
      isPagiExecuting.current
    ) {
      let pagiTask = selectedPrompt?.pagiTask;
      sessionChanged(null);
      sessionIdRef.current = null;
      setQuestion(pagiTask?.task?.prompt);
      submitHandler(null, pagiTask?.task?.prompt, "text", true);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    let queryData =
      urlParser.parse(window.location.href, { parseQueryString: true }).query ||
      {};
    let queryvalue = queryData.query || "";
    let newSession = queryData.newSession || "";
    if (queryData?.reference) {
      fetchInfoBaseOnReference(queryData?.reference);
    }

    if (queryData?.sharedSession) {
      setShowModal(false);
      sessionChanged(queryData?.sharedSession);
      history.replace("/chat");
    }
    if (Object.keys(config).length > 0 && queryvalue) {
      if (newSession && newSession == "yes") {
        sessionChanged(null);
        sessionIdRef.current = null;
        setQuestion(queryvalue);
        submitHandler(null, queryvalue, "text", true);
        history.replace("/chat");
        queryvalue = null;
      } else {
        setQuestion(queryvalue);
        submitHandler(null, queryvalue);
        history.replace("/chat");

        queryvalue = null;
        // window.history.replaceState({}, document.title, '/chat')
      }
    } else if (
      Object.keys(config).length > 0 &&
      queryData?.agiExecution &&
      sessionListProvider.isFetched
    ) {
      executePagi(queryData?.agiExecution);
      setPagiCall(true);
      history.replace("/chat");
    }
  }, [config, search, sessionListProvider]);

  useEffect(() => {
    const cacheData = JSON.parse(localStorage.getItem("guestUserSession"));
    let currentTime = new Date();
    if (
      cacheData &&
      new Date(cacheData.expire).getTime() > currentTime.getTime()
    ) {
      sessionIdRef.current = cacheData.sessionId;
      fetchInfo(cacheData?.sessionId);
    } else {
      localStorage.removeItem("guestUserSession");
    }
  }, []);

  // Session Related Events
  const checkNewMemoryCompatibility = () => {
    let status = false;
    let limit = config[membership]?.maxMemoryLimit;

    let memoryCount = Object.keys(sessionList).length;
    if (limit > memoryCount) {
      status = true;
    }
    return status;
  };

  /**
   * PRO account modal
   * @param {*} _id
   * @author Emran
   * @date 30 March 2023
   */
  const [getPro, setGetPro] = useState(false);
  const closeProModal = () => {
    setGetPro(false);
  };

  const getMyIp = async () => {
    let ip = await axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        return response?.data?.ip || "";
      })
      .catch((err) => {
        console.error("Problem fetching my IP", err);
        return null;
      });
    return ip;
  };

  const executeShareReward = async (sessionId) => {
    if (executingshareReward) {
      return false;
    }
    setExecutingshareReward(true);
    let ip = await getMyIp();

    let payload = {
      ip,
      sessionId,
      source: "chat",
    };
    if (ip && sessionId) {
      let encodedString = new Buffer(JSON.stringify(payload)).toString(
        "base64"
      );
      await userPrivateRequest
        .post("/share/execute", {
          payload: encodedString,
        })
        .then((response) => {
          authData.profileFetch();
        })
        .catch((err) => {});
    } else {
      // // console.log('Reward data not updated')
      // // console.log(payload)
    }
  };

  useEffect(() => {
    sessionChanged(currentSessionId);
  }, [currentSessionId]);

  const sessionChanged = (_id) => {
    if (!_id) {
      return;
    }
    if (!_id && !checkNewMemoryCompatibility()) {
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);
      return;
    }
    if (queryvalueV === "voice") {
      history.push(`/chat?sharedSession=${_id}`);
      return;
    }
    sessionIdRef.current = _id;
    fetchInfo(_id);
  };

  const fetchInfo = async (sessionId) => {
    setInitialFetch(false);
    if (!sessionId) {
      setMessages([]);
      setInitialFetch(true);
      return;
    }
    let queryData =
      urlParser.parse(window.location.href, { parseQueryString: true }).query ||
      {};
    if (queryData?.sharedSession) {
      executeShareReward(queryData?.sharedSession);
    }

    try {
      const chatHistory = await userPrivateRequest.get(
        `/guest-chat/history?sessionId=${sessionId}`
      );
      setCurrentSessionInfo(chatHistory.data.session);
      if (chatHistory?.data?.session?.selectedPrompt) {
        setSelectedPrompt(chatHistory?.data?.session?.selectedPrompt);
      } else {
        setSelectedPrompt({});
      }
      let userMessages = chatHistory.data.records.map((record) => {
        return {
          id: record._id,
          criteria: record.criteria,
          human: {
            text: record.prompt,
            time: record.createdAt,
          },
          ai: {
            text: record.answer,
            time: record.updatedAt,
            fullLoaded: true,
            video: record?.video,
            files: record.files,
          },
          searchResult: {
            ...record?.searchEngineData,
            organic: record?.searchEngineData?.organic
              ? record.searchEngineData.organic
              : [],
            images: record?.searchEngineData?.images
              ? record?.searchEngineData?.images
              : [],
            loading: false,
          },
          user: record.user,
          includeGoogleData: record.includeGoogleData,
        };
      });

      let lastMessage = userMessages[userMessages.length - 1];
      console.log("lastMessage", lastMessage);
      setSelectedVoice(lastMessage?.searchResult?.voice ?? {});
      setMessages(userMessages);
      setInitialFetch(true);
      sessionListProvider.fetchSession();
      // // console.log("userMessages", userMessages);
    } catch (err) {}
  };

  console.log("selectedVoice", selectedVoice);
  const fetchInfoBaseOnReference = async (sessionId) => {
    setInitialFetch(false);
    try {
      const chatHistory = await userPublicRequest.get(
        `/guest-chat/information/${sessionId}`
      );
      const record = chatHistory?.data?.chat;

      let userMessages = [
        {
          id: record._id,
          criteria: record.criteria,
          human: {
            text: record.prompt,
            time: record.createdAt,
          },
          ai: {
            text: record.answer,
            time: record.updatedAt,
            fullLoaded: true,
            video: record?.video,
          },
          searchResult: {
            ...record.searchEngineData,
            organic: record.searchEngineData.organic
              ? record.searchEngineData.organic
              : [],
            images: record.searchEngineData.images
              ? record.searchEngineData.images
              : [],
            loading: false,
          },
          includeGoogleData: record.includeGoogleData,
          user: record.user,
        },
      ];
      setMessages(userMessages);
      storeChatToBackend(
        record.prompt,
        record.answer,
        record?.searchEngineData,
        "text"
      );
      setInitialFetch(true);
      history.replace("/chat");
    } catch (err) {
      setInitialFetch(true);
      history.replace("/chat");
    }
  };

  function removeHTMLTagsAndReferences(str) {
    // Remove HTML tags
    let result = str?.replace(/<\/?[^>]+(>|$)/g, "");

    // Remove markdown syntax
    result = result?.replace(/([_*~`])/g, "");

    // Remove square brackets with numbers
    result = result?.replace(/\[\d+\]/g, "");

    return result;
  }
  const generateOneClickVideo = async (
    orientation,
    language,
    voice,
    messagesU,
    sessionId
  ) => {
    const uniqueId = generateUniqueId();
    let userMessages = [...messages];

    if (messagesU) {
      userMessages = [...messagesU];
    }
    const withoutVideo = userMessages.filter((um) => um?.criteria !== "video");
    const lastMessage = withoutVideo[withoutVideo.length - 1];
    userMessages.push({
      id: uniqueId,
      criteria: "video",
      human: {
        text: `Video of ${lastMessage?.human?.text}`,
        time: Date.now(),
      },
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: {
          _id: uniqueId,
        },
      },
      searchResult: {
        loading: true,
        data: [],
      },
      includeGoogleData: "no",
    });
    setMessages(userMessages);
    let videoContent = lastMessage?.ai?.text;
    let plainString = removeHTMLTagsAndReferences(videoContent);
    let first30Chars = plainString.substring(
      0,
      orientation
        ? Number(VIDEO_CONFIGURATION?.shortGptCharacterLimitInVideoCreation)
        : Number(
            config?.global?.ELAI_GENERAL_CONFIG?.value?.speechCharacterLimit
          )
    );
    let video = {};

    if (!orientation) {
      video = await userPrivateRequest.post("/create-video", {
        videoName: lastMessage?.human?.text.substring(0, 30),
        speech: first30Chars,
      });
    }
    let videoShortGpt = {};
    if (orientation) {
      videoShortGpt = await userPrivateRequest
        .post("/text_to_video/generate_video", {
          includeCaption,
          orientation,
          script: first30Chars,
          language,
          voice,
          voice_module: "elevenLabs",
        })
        .catch((err) => {
          console.error("video error", err.message);
          if (err.message === "Insufficient Credit in account") {
            modalData.setCurrentModalName("membershipPackageModal");
          }
        });
    }

    let findMessage = userMessages.find((um) => um?.id === uniqueId);
    let messageI = userMessages.findIndex((um) => um?.id === uniqueId);
    findMessage = {
      ...findMessage,
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: orientation
          ? { ...videoShortGpt?.data?.data, type: "shortGpt" }
          : { ...video?.data?.createVideo, type: "elai" },
      },
    };
    userMessages[messageI] = findMessage;
    setMessages(userMessages);

    storeChatToBackend(
      lastMessage?.human?.text,
      "",
      {},
      "video",
      "no",
      "",
      orientation
        ? { ...videoShortGpt?.data?.data, type: "shortGpt" }
        : { ...video?.data?.createVideo, type: "elai" },
      "yes",
      sessionId ? sessionId : null
    ).then((chat) => {
      findMessage.id = chat._id;
      const userMessagess = [...userMessages];
      userMessagess[messageI] = findMessage;
      setMessages(userMessagess);
      // setCreateAutoVideo(false);
      timeoutExecuted.current = false;
    });
  };

  const continueMessage = async () => {
    setQuestion("Continue");
    submitHandler(null, "Continue");
  };

  const promptGeneration = useCallback(
    (prompt) => {
      if (prompt?._id === selectedPrompt?._id) {
        setSelectedPrompt({});
        return;
      }
      setSelectedPrompt(prompt);
    },
    [selectedPrompt]
  );

  const storeChatToBackend = async (
    prompt,
    answer,
    searchEngineData,
    criteria,
    includeGoogleData = "yes",
    costableString = "",
    video = {},
    isDiscovered = "yes",
    sessionId = null,
    openAiThreadId = ""
  ) => {
    return userPrivateRequest
      .post("/guest-chat/store", {
        prompt,
        answer,
        searchEngineData,
        ...(sessionIdRef.current && { session: sessionIdRef.current }),
        ...(criteria && { criteria }),
        ...(includeGoogleData && { includeGoogleData }),
        ...(video && { video }),
        costableString,
        isDiscovered,
        ...(selectedPrompt && { selectedPrompt }),
        ...(sessionId && { session: sessionId }),
        ...(openAiThreadId && { openAiThreadId: openAiThreadId }),
      })
      .then((response) => {
        let derivedSessionId = response.data.chat.session._id || null;
        let chatId = response.data.chat._id || null;
        // Store and modify session id
        if (!sessionIdRef.current && derivedSessionId) {
          sessionListProvider.addNewSession(
            derivedSessionId,
            prompt,
            isDiscovered
          );
          sessionIdRef.current = derivedSessionId;
          setCurrentSessionInfo(response.data.chat.session);
        }
        authData.profileFetch();
        return response.data.chat;
      });
  };

  useEffect(() => {
    let interval;

    if (isScrollLocked) {
      interval = setInterval(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup on component unmount or when the effect runs again
  }, [isScrollLocked]);

  function parseContent(content) {
    try {
      // Try to parse the content as JSON

      console.log("Parsable content: ", content);
      const parsed = JSON.parse(content);
      // console.log(parsed, 'Parsed as JSON');
      return true;
    } catch (e) {
      // If parsing fails, it's likely a normal string
      console.log(e.message, "Content is a normal string", 830);
      if (e.message.includes("Unterminated string in JSON")) {
        return "UNPARSED";
      }
      return false;
    }
  }
  let handleSubmitPromptBtnClicked = async (
    prompt,
    { userMessagess, findMessage, findIndex, text, chatId, responseType },
    finalMessage,
    googleResponse,
    isNotStore = false
  ) => {
    console.log(prompt, findMessage?.human?.text, "901901901");
    let model = config[membership].model;
    let temperature = config[membership].aiTemperature;
    let top_p = config[membership].topP;
    let max_tokens = config[membership].maxToken;
    let frequency_penalty = config[membership].frequencyPenalty;
    let presence_penalty = config[membership].presencePenalty;

    if (selectedPrompt) {
      if (selectedPrompt?.model) {
        model = selectedPrompt?.model;
      }
      if (selectedPrompt?.aiTemperature !== undefined) {
        temperature = selectedPrompt?.aiTemperature;
      }
      if (selectedPrompt?.topP) {
        top_p = selectedPrompt?.topP;
      }
      if (selectedPrompt?.maxToken) {
        max_tokens = selectedPrompt?.maxToken;
      }
      if (selectedPrompt?.frequencyPenalty !== undefined) {
        frequency_penalty = selectedPrompt.frequencyPenalty;
      }
      if (selectedPrompt?.presencePenalty !== undefined) {
        presence_penalty = selectedPrompt.presencePenalty;
      }
    }

    let promptContentInText = prompt
      .map((i) => {
        return i.content;
      })
      .join(" ");

    let references = [];
    let uSelectedPropmt = {
      ...selectedPrompt,
    };
    if (!uSelectedPropmt._id) {
      uSelectedPropmt = {
        ...assistModal,
      };
    }
    if (
      prompt !== "" &&
      uSelectedPropmt?.assistant?.assistantId &&
      uSelectedPropmt?.promptType === "assistant"
    ) {
      const fileIds = fileUploads.map((fu) => {
        return fu?.response?.id;
      });
      console.log(fileUploads, "fileUploads");

      console.log(currentSessionInfo, "currentSessionInfo");
      const payload = {
        thread_id: currentSessionInfo?.openAiThreadId
          ? currentSessionInfo?.openAiThreadId
          : "",
        assistant_id: uSelectedPropmt?.assistant?.assistantId,
        message: findMessage?.human?.text,
        file_ids: fileIds.length > 0 ? fileIds : [],
        selectedPrompt: uSelectedPropmt,
        // includeTts: "yes",
        ...(assistModal?._id && { includeTts: "yes" }),
        ...(isNotStore && { ignoreSessionCreation: "yes" }),
      };

      setFileUploads([]);

      let url =
        process.env.REACT_APP_API_URL + `/assistant/message/add-run/streaming`;
      const token = Cookies.get("token");
      let fullObj = [];
      // Initiating the stream with a POST request
      // console.log(token, url, currentSessionInfo, 'Assistant Api')

      let unParsedString = "";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(async ({ done, value }) => {
                  // console.log("Assistant Api backend one", done, value);
                  if (done) {
                    controller.enqueue(value);

                    let res = new TextDecoder().decode(value);
                    let pRes = {};

                    // if (res && parseContent(res)) {
                    //   pRes = JSON.parse(res);

                    //   console.log("pRes end: ", pRes);
                    // }
                    controller.close();
                    // console.log("Assistant Api backend done")
                    if (partiallyStoppedRef.current) {
                      findMessage.includeGoogleData = "no";
                    }
                    findMessage.ai.fullLoaded = true;
                    const userMessages = [...userMessagess];
                    userMessages[findIndex] = findMessage;
                    setMessages(userMessages);
                    let costableString = "";
                    setssLoading(false);
                    setDisableIcon(false);
                    setScrollLocked(false);
                    const parseValue = JSON.parse(resultRef.current);
                    // console.log("Assistant Api backend done 2", parseValue)
                    const sessionRefr = parseValue.find(
                      (pv) => pv?.type === "final_response"
                    )?.session;
                    if (!sessionIdRef.current && !isNotStore) {
                      sessionListProvider.addNewSession(
                        sessionRefr?._id,
                        findMessage?.human?.text,
                        false
                      );
                      sessionIdRef.current = sessionRefr?._id;
                      setCurrentSessionInfo(sessionRefr);
                    }
                    console.log(
                      findMessage,
                      "findMessagefindMessagefindMessage"
                    );
                    setCurrentMessage(findMessage);

                    if (!isNotStore) {
                      await storeChatToBackend(
                        finalMessage,
                        resultRef.current,
                        { ...googleResponse, usedReferences: references },
                        responseType,
                        findMessage.includeGoogleData,
                        costableString,
                        {},
                        isPagiExecuting.current && pagiTaskQueue.length != 1
                          ? "no"
                          : "yes",
                        sessionIdRef.current,
                        parseValue?.thread_id
                      ).then((chat) => {
                        findMessage.id = chat._id;
                        const userMessages = [...userMessagess];
                        userMessages[findIndex] = findMessage;
                        setMessages(userMessages);
                        if (timeoutExecuted.current) {
                          generateOneClickVideo(
                            orientation,
                            language,
                            voice,
                            userMessages,
                            chat?.session?._id
                          );
                        }
                        responseProgressRef.current = false;
                      });
                    }

                    if (isPagiExecuting.current) {
                      let task = selectedPrompt?.pagiTask?.task ?? {};
                      let executionId = selectedPrompt?.pagiTask?.id ?? null;

                      PushNotification(
                        executionId,
                        `PagiUpdate-${executionId}`,
                        {
                          id: executionId,
                          data: { ...task, status: "confirmed" },
                        }
                      );

                      isPagiExecuting.current = false;
                      sessionIdRef.current = null;
                    }
                    return;
                  } else {
                    try {
                      controller.enqueue(value);

                      let res = new TextDecoder().decode(value);
                      let pRes = {};

                      if (res && parseContent(res) == "UNPARSED") {
                        unParsedString = res;
                      } else if (
                        res &&
                        parseContent(unParsedString.concat(res))
                      ) {
                        console.log(
                          "Combined feed",
                          unParsedString.concat(res)
                        );
                        pRes = JSON.parse(unParsedString.concat(res));
                        unParsedString = "";

                        console.log("pRes: ", pRes);

                        if (responseProgressRef.current) {
                          const fRef = fullObj.find(
                            (fo) => fo?.refId === pRes?.refId
                          );
                          const fiRef = fullObj.findIndex(
                            (fo) => fo?.refId === pRes?.refId
                          );
                          if (fRef) {
                            fullObj[fiRef] = pRes;
                          } else {
                            fullObj.push(pRes);
                          }

                          resultRef.current = JSON.stringify(fullObj) || "";
                          // // console.log("Assistant Api", JSON.parse(resultRef.current));
                          findMessage.ai.text = resultRef.current;
                          findMessage.ai.load = false;
                          findMessage.ai.time = Date.now();
                          findMessage.ai.chatId = chatId;
                          // findMessage.searchResult.usedReferences = references;
                          const userMessages = [...userMessagess];
                          userMessages[findIndex] = findMessage;
                          setMessages(userMessages);
                        }
                      }

                      // if(!res || parseContent(res)) {
                      //   return
                      // }
                      // console.log("Assistant Api backend else three", pRes);
                    } catch (err) {
                      // console.log("Assistant Api backend err", err);
                    }

                    return pump();
                  }
                });
              }
            },
          });
        })
        .catch((err) => console.error("Stream failed:", err));
      return;
    }

    if (prompt !== "") {
      setResult("");
      // console.log(prompt, 'userMessages')
      let url = `${process.env.REACT_APP_API_URL}/openai/completion`;
      let data = {
        model,
        messages: prompt,
        temperature,
        top_p,
        max_tokens,
        stream: true,
        n: 1,
        frequency_penalty,
        presence_penalty,
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        // setSelectedPrompt({});
        setScrollLocked(true);
        const r = response.body;
        if (!r) {
          return;
        }
        const d = new TextDecoder("utf8");
        const reader = await r.getReader();
        let fullText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            if (partiallyStoppedRef.current) {
              findMessage.includeGoogleData = "no";
            }
            findMessage.ai.fullLoaded = true;
            const userMessages = [...userMessagess];
            userMessages[findIndex] = findMessage;
            setMessages(userMessages);
            let costableString = promptContentInText + fullText;
            setssLoading(false);
            setDisableIcon(false);
            setScrollLocked(false);
            setCurrentMessage(findMessage);
            if (!isNotStore) {
              await storeChatToBackend(
                finalMessage,
                resultRef.current,
                { ...googleResponse, usedReferences: references },
                responseType,
                findMessage.includeGoogleData,
                costableString,
                {},
                isPagiExecuting.current && pagiTaskQueue.length != 1
                  ? "no"
                  : "yes"
              ).then((chat) => {
                findMessage.id = chat._id;
                const userMessages = [...userMessagess];
                userMessages[findIndex] = findMessage;
                setMessages(userMessages);
                if (timeoutExecuted.current) {
                  generateOneClickVideo(
                    orientation,
                    language,
                    voice,
                    userMessages,
                    chat?.session?._id
                  );
                }
              });
            }

            if (isPagiExecuting.current) {
              let task = selectedPrompt?.pagiTask?.task ?? {};
              let executionId = selectedPrompt?.pagiTask?.id ?? null;

              PushNotification(executionId, `PagiUpdate-${executionId}`, {
                id: executionId,
                data: { ...task, status: "confirmed" },
              });

              isPagiExecuting.current = false;
              sessionIdRef.current = null;
            }
            break;
          } else {
            const decodedString = d.decode(value);
            // console.log(" assistant value: " + decodedString);

            fullText += decodedString;

            if (responseProgressRef.current) {
              let formatReference = formatReferenceText(
                fullText,
                googleResponse.organic,
                responseType
              );
              let existing = findMessage.searchResult.usedReferences || [];
              references = uniq([
                ...uniq(existing),
                ...formatReference.references,
              ]);
              resultRef.current = formatReference.text || "";
              findMessage.ai.text = resultRef.current;
              findMessage.ai.load = false;
              findMessage.ai.time = Date.now();
              findMessage.ai.chatId = chatId;
              findMessage.searchResult.usedReferences = references;
              const userMessages = [...userMessagess];
              userMessages[findIndex] = findMessage;
              setMessages(userMessages);
            }
          }
        }
      });
    } else {
      alert("Please insert a prompt!");
    }
  };

  function generateUniqueId() {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  }

  const searchGoogle = async (query, userMessages) => {
    let translationLanguage = config.translationLanguage || "en";
    let languageName = "English";
    if (translationLanguage === "ko") {
      languageName = "Korean";
    }
    if (userMessages.length > 1) {
      let historyPromptData = [];
      historyPromptData.push({
        role: "system",
        content: `The user will provide a set of google search keywords. As the user give you more things to search,  Simplify the google search results based on the context. You have to give a good streamlined google search terms that will express the core idea of the user's request. Add the search terms together and come up with a good google search terms that would best express the intent of the user. Always use ONLY JSON format as a response. DO NOT DEVIATE FROM THE TOPIC. Include the original context in every new search term generated.\n"""\nExample:\nUser: Tell me about the origins of the bible.\n User: Tell me more about this.\nAssistant: {"search_terms": "details about the origins of the bible}\nUser: hmm....I need to know about the history. \nAssistant: {"search_terms": "details about the history of the bible}\n """\n Always RESPOND in ${languageName} Language.`,
      });

      userMessages.forEach((i, index) => {
        historyPromptData.push({ role: "user", content: i.human.text });
        if (i.searchResult?.googleSearchQuery && index > 0) {
          historyPromptData.push({
            role: "assistant",
            content: JSON.stringify({
              search_terms: i.searchResult?.googleSearchQuery,
            }),
          });
        }
      });
      query = await userPrivateRequest
        .post("openai/completion", {
          model: "gpt-3.5-turbo",
          messages: historyPromptData,
          temperature: 0.2,
        })
        .then((response) => {
          // // console.log("search response AI",response.data)
          let simplified = response.data.choices[0]?.message?.content ?? "";

          if (simplified) {
            simplified = JSON.parse(simplified)?.search_terms;
          }
          return simplified;
        })
        .catch((error) => {
          // console.log("search error", error.message);
          return query;
        });
    }
    let limit = config[membership].searchEngineResultLimit || 10;
    let response = await getGoogleData(query, limit);
    return { ...response, googleSearchQuery: query };
  };

  const [ssLoading, setssLoading] = useState(false);

  const submitHandler = async (
    e,
    messaget,
    responseType = "text",
    isNew = false,
    includeGoogleData = "yes",
    isNotStore = false
  ) => {
    if (selectedPrompt?.promptType === "tts") {
      responseType = "tts";
    }
    setssLoading(true);
    setCriteria(responseType);
    let isAuthenticated = authData.authState.isAuthenticated || false;
    if (e) {
      e.preventDefault();
      if (!isAuthenticated) {
        router.push("/auth/sign-in");
      }
    }

    if (!messaget && !question) return;
    responseProgressRef.current = true;
    partiallyStoppedRef.current = false;

    let queryLimit = config[membership]?.queriesInMemory;

    if (!sessionIdRef.current && !checkNewMemoryCompatibility()) {
      // history.push('/auth/sign-up')

      // router.push("/auth/sign-in");
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);

      // setGetPro(true);
      // toast.error('Sign up for PRO to unlock more memories.')
      return;
    } else if (sessionIdRef.current && queryLimit <= messages.length) {
      // setGetPro(true);
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);
      // toast.error('Sign up for PRO to extend query limit in chat.')
      return;
    }

    console.log("selectedVoice", selectedVoice);
    let finalMessage = question;
    if (messaget) {
      finalMessage = messaget;
    }

    if (selectedPrompt?.assistant?.assistantId && fileUploads.length > 0) {
      finalMessage = JSON.stringify({
        message: finalMessage,
        files: fileUploads,
      });
    }

    if (selectedPrompt?.includeWebSearchInPrompt == "no") {
      includeGoogleData = "no";
    } else if (currentSessionInfo?.includeGoogleData == "no") {
      includeGoogleData = "no";
    } else if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.includeGoogleData == "no") {
        includeGoogleData = "no";
      }
    }

    const creditBalance = authData.authState.user.creditBalance;
    if (Object.keys(selectedPrompt).length) {
      let freeStatus = isKor
        ? selectedPrompt?.toFreeKo
        : selectedPrompt?.toFree;

      let checkScope = hasModuleScopeEligibility(
        selectedPrompt,
        authData?.authState?.user?.scopes ?? {},
        authData?.authState?.user
      );

      let web3Auth =
        configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES"
          ? true
          : false;
      if (web3Auth && membership === "free" && !freeStatus) {
        modalData.setCurrentModalName("moduleEligibilityException");

        return;
      } else if (!web3Auth && !checkScope) {
        modalData.setCurrentModalName("moduleEligibilityException");

        return;
      }
    }
    if (membership == "pro" && creditBalance <= 0) {
      if (configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES") {
        toast.error("Insufficient " + creditLabel);
        router.push("/profile");
      } else {
        modalData.setCurrentModalName("membershipPackageModal");
      }

      return;
    }

    const uniqueId = generateUniqueId();
    let userMessages = messages;
    if (isNew) {
      userMessages = [
        {
          id: uniqueId,
          criteria: responseType,
          human: {
            text: finalMessage,
            time: Date.now(),
          },
          ai: {
            text: "",
            load: true,
            fullLoaded: false,
          },
          searchResult: {
            loading: true,
            data: [],
          },
          includeGoogleData,
          user: authData.authState.user,
        },
      ];
    } else {
      userMessages.push({
        id: uniqueId,
        criteria: responseType,
        human: {
          text: finalMessage,
          time: Date.now(),
        },
        ai: {
          text: "",
          load: true,
          fullLoaded: false,
        },
        searchResult: {
          loading: true,
          data: [],
        },
        includeGoogleData,
      });
    }
    setQuestion("");
    setMessages(userMessages);

    const findIndex = userMessages.length - 1;
    const findMessage = userMessages[findIndex];
    findMessage.ai.load = true;
    userMessages[findIndex] = findMessage;
    console.log(userMessages, findMessage, "userMessagesuserMessages");
    let translationLanguage = config.translationLanguage || "en";
    let languageName = "English";
    if (translationLanguage === "ko") {
      languageName = "Korean";
    }

    // let selectedVoice = queryDataV.voice || "";
    console.log("selectedPrompt", selectedPrompt);
    if (selectedPrompt?.promptType === "tts") {
      console.log("selectedVoice", selectedVoice);

      const payload = {
        input: finalMessage,
        voiceId: selectedVoice?.id ?? "",
      };
      userPrivateRequest
        .post("/elevenLabs/tts", payload)
        .then(async (res) => {
          console.log(res);
          const ttsFile = res?.data?.file?.Location;
          console.log(ttsFile, "ttsFile");

          findMessage.ai.fullLoaded = true;
          findMessage.ai.load = false;
          findMessage.ai.text = ttsFile;
          userMessages[findIndex] = findMessage;
          // setMessages(userMessages);

          let costableString = finalMessage;
          setssLoading(false);
          setDisableIcon(false);
          setScrollLocked(false);
          setCurrentMessage(findMessage);
          if (true) {
            await storeChatToBackend(
              finalMessage,
              ttsFile,
              {
                voice: selectedVoice,
              },
              responseType,
              "no",
              costableString,
              {},
              "yes",
              sessionIdRef.current,
              ""
            ).then((chat) => {
              findMessage.id = chat._id;
              userMessages[findIndex] = findMessage;
              setMessages(userMessages);
            });
          }
        })
        .catch((err) => {
          toast("Failed to generate response", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      return;
    } else if (selectedPrompt?.allowVideoCreate === "REPLICATE") {
      const payload = {
        prompt: finalMessage,
      };
      userPrivateRequest
        .post("/video-generation/generate_video_with_replicate", payload)
        .then(async (res) => {
          const videoInfo = res?.data?.data ?? {};

          await storeChatToBackend(
            finalMessage,
            "",
            {
              videoInfo,
            },
            "video",
            "no",
            "",
            videoInfo,
            "yes",
            sessionIdRef.current,
            ""
          ).then((chat) => {
            findMessage.ai.fullLoaded = true;
            findMessage.ai.load = false;
            findMessage.ai.text = "";
            findMessage.ai.video = videoInfo;
            findMessage.criteria = "video";
            userMessages[findIndex] = findMessage;
            findMessage.id = chat._id;
            userMessages[findIndex] = findMessage;
            setMessages(userMessages);
            setssLoading(false);
            setDisableIcon(false);
            setScrollLocked(false);
            setCurrentMessage(findMessage);
          });
        })
        .catch((err) => {
          findMessage.ai.fullLoaded = true;
          findMessage.ai.load = false;
          findMessage.ai.text = "";
          findMessage.criteria = "text";
          userMessages[findIndex] = findMessage;
          userMessages[findIndex] = findMessage;
          setMessages(userMessages);
          setssLoading(false);
          setDisableIcon(false);
          setScrollLocked(false);
          setCurrentMessage(findMessage);
          console.log(err.response, "err.response.data");
          toast(err.response.data?.message ?? "Failed to generate response", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      return;
    }

    // // console.log('currentSessionInfo', currentSessionInfo)

    try {
      let defaultInitialPrompt = config[membership].initialPrompt;
      if (currentSessionInfo?.flag == "definedPrompt") {
        defaultInitialPrompt = currentSessionInfo?.initialPrompt;
      }

      let finalPrompt = config[membership]?.finalPrompt || "";
      if (currentSessionInfo?.flag == "definedPrompt") {
        finalPrompt = currentSessionInfo?.finalPrompt;
      }

      // use defined first and final prompt for admin generated prompts
      if (Object.keys(selectedPrompt).length) {
        defaultInitialPrompt = selectedPrompt.initialPrompt || "";
        finalPrompt = selectedPrompt.finalPrompt || "";
      }

      // if(selectedPrompt?.assistant?.assistantId) {

      //   excuteAssistant()
      //   return
      // }

      let googlePromptData = "";
      let googleResponse = {};
      if (includeGoogleData === "yes") {
        googleResponse = await searchGoogle(finalMessage, userMessages);
        let searchEngineResultLimit =
          config[membership].searchEngineResultLimit || 10;
        const searchData = googleResponse.organic.slice(
          0,
          searchEngineResultLimit
        );
        findMessage.searchResult = {
          ...googleResponse,
          includeGoogleData,
          loading: false,
        };
        userMessages[findIndex] = findMessage;
        setMessages(userMessages);

        let arrayData = searchData
          .slice(0, numberOfReferenceInPrompt)
          .map((i, index) => {
            return `{web reference number:${index + 1},content: "${
              i.snippet
            }",link:"${i.link}"}`;
            // return `^^${index + 1}^^: ${i.title} \n ${i.link} \n ${i.snippet}`;
          });
        googlePromptData = arrayData.join(" ");
      }

      defaultInitialPrompt = defaultInitialPrompt
        .replaceAll("[TARGET_LANGUAGE]", languageName)
        .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
      // .replaceAll("\n", "");

      finalPrompt = finalPrompt
        .replaceAll("[TARGET_LANGUAGE]", languageName)
        .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
      // .replaceAll("\n", "");

      // Building array of messages

      let history = [];
      if (defaultInitialPrompt.replace(/[^\p{L}]/gu, "")) {
        history.push({
          role: "system",
          content: defaultInitialPrompt,
        });
      }
      let firstQuestionInPrompt = parseInt(
        config[membership].firstQuestionInPrompt
      );
      let latestQuestionInPrompt = parseInt(
        config[membership].latestQuestionInPrompt
      );
      let firstResponseInPrompt = parseInt(
        config[membership].firstResponseInPrompt
      );
      let latestResponseInPrompt = parseInt(
        config[membership].latestResponseInPrompt
      );
      let arraySize = userMessages.length;
      console.log(finalPrompt, "userMessages");
      // return;
      console.log("Before Question Text", finalPrompt);
      userMessages.map((i, index) => {
        // Question block
        let questionText = i.human.text;
        if (index == arraySize - 1) {
          // Question block
          // let inputText = i.human.text;
          if (index === arraySize - 1) {
            if (selectedPrompt?.prompt) {
              let inputText = selectedPrompt?.prompt;
              console.log(inputText, "userMessages");
              questionText = inputText
                .replaceAll("[TOPIC]", i.human.text)
                .replaceAll("[TARGET_LANGUAGE]", languageName)
                .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
              console.log(questionText, "userMessages");
              // .replaceAll("\n", "");

              // if (finalPrompt.replace(/[^\p{L}]/gu, "")) {
              //   questionText += `${inputText} \n ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              // }
            } else {
              if (finalPrompt.includes("[TOPIC]")) {
                questionText = `ULTIMATE INSTRUCTIONS: ${finalPrompt.replaceAll(
                  "[TOPIC]",
                  questionText
                )}`;
              } else {
                questionText = `${questionText}. ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              }
              // if (finalPrompt.replace(/[^\p{L}]/gu, "")) {
              //   questionText = `ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              // } else {
              //   console.log("End Question Block", finalPrompt, questionText);
              //   questionText = questionText.replaceAll("[TOPIC]", questionText);
              // }
              // .replaceAll("\n", "");
            }
          }
        }

        if (firstQuestionInPrompt > index) {
          history.push({ role: "user", content: questionText });
        } else if (userMessages.length - latestQuestionInPrompt - 2 < index) {
          history.push({ role: "user", content: questionText });
        }
        // End Question Block

        // Answer Block
        if (i.ai.text) {
          let formattedAnswer = i.ai.text
            .replace(/<[^>]+>/g, "")
            .replace(/\[(.*?)\]/g, "");
          // .slice(0, 100);

          if (firstResponseInPrompt > index) {
            history.push({ role: "assistant", content: formattedAnswer });
          } else if (userMessages.length - latestResponseInPrompt - 2 < index) {
            history.push({ role: "assistant", content: formattedAnswer });
          }
        }
        // end Answer block
        return null;
      });

      handleSubmitPromptBtnClicked(
        history,
        {
          userMessagess: userMessages,
          findMessage: findMessage,
          findIndex: findIndex,
          text: "parsedData",
          chatId: uniqueId,
          responseType,
        },
        finalMessage,
        googleResponse,
        isNotStore
      );
    } catch (err) {
      findMessage.ai.text = isKor
        ? config.global.EXCEPTION_MESSAGE_KR.value
        : config.global.EXCEPTION_MESSAGE.value || "";
      findMessage.ai.load = false;
      findMessage.ai.time = Date.now();
      userMessages[findIndex] = findMessage;

      setMessages([]);
      setMessages(userMessages);
    }
    return;
  };

  const updateMessage = async (id, video) => {
    let userMessages = [...messages];
    let findMessage = userMessages.find((um) => um?.id === id);
    let messageI = userMessages.findIndex((um) => um?.id === id);
    findMessage = {
      ...findMessage,
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: video,
      },
    };
    userMessages[messageI] = findMessage;
    setMessages(userMessages);
    userPublicRequest
      .post("/guest-chat/update", {
        _id: id,
        video,
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const {
    isOpen: isShareModal,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const {
    isOpen: isVideoModal,
    onOpen: onVideoModalOpen,
    onClose: onVideoModalClose,
  } = useDisclosure();

  const setVoiceModule = useCallback(
    (data) => {
      console.log(assistModal, "assistModal");
      setAssistModal(data);
      setCurrentSessionInfo({});
    },
    [assistModal, setAssistModal]
  );

  const [categories, setCategories] = useState();
  const [selectedFilter, setSelectedFilter] = useState("");
  const setFilterSelected = (str) => setSelectedFilter(str);
  const fetchCategories = async () => {
    try {
      const results = await userPublicRequest.get(`/prompt-categories`);
      setCategories(results?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  let columnsData = [];

  columnsData = useMemo(
    () =>
      categories?.map((cat) => {
        return {
          id: cat?._id,
          value: cat?._id,
          label: cat?.title,
          labelKr: cat?.titleKr,
        };
      }),
    [categories]
  );

  const handleStopResponse = () => {
    setssLoading(false);
    setDisableIcon(true);
    responseProgressRef.current = false;
    partiallyStoppedRef.current = true;
  };

  const handleRegenerate = () => {
    let newData = messages;
    if (messages.length <= 0) {
      toast("No message found.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let index = messages.length - 1;
    let lastMessage = messages[index];
    let removed = newData.splice(-1);
    setMessages(newData);
    let queryvalue = lastMessage.human.text || "";
    // deleteChatToBackend(lastMessage.id);
    setQuestion(queryvalue);
    submitHandler(
      null,
      queryvalue,
      lastMessage.criteria,
      false,
      lastMessage.includeGoogleData
    );
    // alert(lastMessage.criteria)
  };

  const deleteChatToBackend = async (_id) => {
    return userPublicRequest
      .post("/guest-chat/delete/" + _id, {})
      .then((response) => {
        return response.data;
      });
  };

  return (
    <Flex
      flexDirection="column"
      gridArea={{ xl: "1 / 1 / 4 / 4", "2xl": "1 / 1 / 4 / 4" }}
      overflow={"hidden"}
      p="0"
      px="12px"
      pb="80px"
    >
      {!authData?.authState?.isAuthenticated && (
        <>
          <div
            className="modal-review-preview"
            onClick={() => {
              onOpen();
            }}
          ></div>
        </>
      )}

      <Flex alignItems="center" justifyContent="flex-end" pr="22px" mb="5px">
        {configData?.config?.global?.LANGUAGE_ACTIVE?.value === "YES" && (
          <Menu isOpen={isLangMenuOpen}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <>
                  <FiGlobe color={"#aea3d0"} />
                  <Text color="#aea3d0" fontSize="16px" pl="2" fontWeight={600}>
                    {isKor ? "한국어" : "EN"}
                  </Text>
                </>
              }
              variant="outline"
              p="0px"
              // width={"75px"}
              height={"20px"}
              borderRadius={"5px"}
              border="none"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              _hover={{ background: "transparent" }}
              _active={{ background: "transparent" }}
              // onMouseEnter={() => setOpen(true)}
              onMouseEnter={() => {
                setIsLangMenuOpen(true);
                setIsProfileMenuOpen(false); // Close the profile dropdown
              }}
              onMouseLeave={() => setIsLangMenuOpen(false)}
            />

            <MenuList
              className={`MENU_LIST ${
                platform === "Chingu" ? "MENU_LIST_CHINGU" : ""
              }`}
              borderRadius="4px"
              bg={"#fff"}
              border="none"
              boxShadow={
                "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
              }
              px="10px"
              onMouseEnter={() => setIsLangMenuOpen(true)}
              onMouseLeave={() => setIsLangMenuOpen(false)}
            >
              <MenuItem
                // command="KO"
                onClick={(e) => {
                  window.location = `/ko`;
                }}
                isChecked={isKor}
                _hover={{ background: "#e9ecef" }}
                _focus={{ bg: "none" }}
                borderRadius="4px"
                py="6px"
                color="#495057"
                fontFamily={"Nunito Sans, sans-serif"}
              >
                <Text fontSize="md">{t("Korean")} </Text>
              </MenuItem>
              <MenuItem
                // command="EN"
                onClick={(e) => {
                  window.location = `/en`;
                }}
                isChecked={!isKor}
                _hover={{ background: "#e9ecef" }}
                _focus={{ bg: "none" }}
                borderRadius="4px"
                py="6px"
                color="#495057"
                fontFamily={"Nunito Sans, sans-serif"}
              >
                <Text fontSize="md">{t("English")} </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
          authData?.authState?.isAuthenticated && (
            <DepositButton metrics="chatpage" />
          )}
      </Flex>

      {!selectedPrompt?.title && !currentSessionId && <Banner />}
      {isVideoModal && (
        <GetVideoConfirmation
          onClose={onVideoModalClose}
          generateOneClickVideo={generateOneClickVideo}
          selectedPrompt={selectedPrompt}
          orientation={orientation}
          setOrientation={setOrientation}
          includeCaption={includeCaption}
          setIncludeCaption={setIncludeCaption}
          language={language}
          setLanguage={setLanguage}
          voice={voice}
          setVoice={setVoice}
        />
      )}
      {isShareModal && (
        <ShareModal
          lan={currentLanguage}
          sessionId={sessionIdRef.current}
          onShareModalClose={onShareModalClose}
          memoryType={"chat"}
        />
      )}
      {!authData?.authState?.isAuthenticated && !isOpen && (
        <Box
          onClick={onOpen}
          bg="transparent"
          position="fixed"
          left={0}
          top={0}
          w="100%"
          h="100%"
        >
          {" "}
        </Box>
      )}
      <Flex direction="column" mt="18px">
        <Flex
          mb="0px"
          justifyContent="center"
          direction={{ base: "column", md: "row" }}
          align={{ base: "center", md: "center" }}
        >
          {/* <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
            Modules
          </Text> */}

          <Flex
            me="10px"
            ms={{ base: "24px", md: "0px" }}
            mt={{ base: "20px", md: "0px" }}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                height: "4px",
                borderRadius: "8px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `${secondaryColor}`,
                borderRadius: "8px",
                border: "4px solid rgba(0, 0, 0, 0.05)",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: `rgba(0, 0, 0, 0.4)`,
              },
              boxShadow: "0px 0px 3px -10px rgba(0, 0, 0, 0.2)",
            }}
            overflowX={"auto"}
            gap="30px"
          >
            {selectedPrompt?.title ? (
              <Box
                flex
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "40px",
                }}
                overflow="hidden"
                mb="5px"
              >
                <h2
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "1.75rem",
                    fontFamily: '"Dm Sans", sans-serif',
                    textAlign: "center",
                  }}
                >
                  {isKor ? selectedPrompt?.titleKr : selectedPrompt?.title}
                </h2>
              </Box>
            ) : (
              <>
                <Text
                  onClick={() => setFilterSelected("")}
                  className={`${selectedFilter === "" ? "active" : ""}`}
                  cursor="pointer"
                  color={selectedFilter === "" ? primaryColor : secondaryColor}
                  fontWeight="500"
                  // ms={{ base: "24px", md: "40px" }}
                  fontSize={"14px"}
                >
                  {t("All")}
                </Text>
                {columnsData?.map((column, i) => (
                  <Text
                    key={column?.id}
                    onClick={() => {
                      console.log("clicked_");
                      setFilterSelected(column?.value);
                    }}
                    color={
                      selectedFilter === column?.value
                        ? primaryColor
                        : secondaryColor
                    }
                    cursor="pointer"
                    fontWeight="500"
                    // ms={{ base: "24px", md: "40px" }}
                    fontSize={"14px"}
                  >
                    {isKor ? column?.labelKr : column?.label}
                  </Text>
                ))}
              </>
            )}
          </Flex>
        </Flex>

        {(!initialFetch || !Object.keys(config).length > 0) && (
          <Box>
            <SkeletonText
              mt="0"
              noOfLines={1}
              spacing="5"
              skeletonHeight="10"
            />
            <SkeletonText mt="4" noOfLines={5} spacing="5" skeletonHeight="3" />
          </Box>
        )}
        {initialFetch && Object.keys(config).length > 0 ? (
          <Box
            // maxHeight="690px"
            h="100%"
            overflow={"auto"}
            sx={{
              position: "relative",
              zIndex: 2,
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "8px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `${secondaryColor}`,
                borderRadius: "8px",
                border: "4px solid rgba(0, 0, 0, 0.05)",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: `rgba(0, 0, 0, 0.4)`,
              },
              // boxShadow: "1px 1px 2px 1px #ddd",
            }}
          >
            {messages?.length > 0 && queryvalueV !== "voice" && !assistModal ? (
              <Box bg={bgColor} borderRadius={"30px"}>
                {messages.length > 0 ? (
                  <Box paddingBottom={10} borderRadius={10}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      pt="15px"
                      pr="10px"
                    >
                      <Box></Box>
                      <Box>
                        <Link
                          to="/"
                          // style={LinkColor("", true)}
                          onClick={(e) => {
                            e.preventDefault();

                            router.push("/chat");
                            window.location.reload();
                          }}
                        >
                          <FiX color="#777777" size={22} />
                        </Link>
                      </Box>
                    </Box>

                    {messages.map((message, index) => {
                      return (
                        <MessageRow
                          refid={sessionIdRef?.current}
                          message={message}
                          config={config}
                          isSearchShow={isSearchShow}
                          iteration={index + 1}
                          isLast={index === messages.length - 1}
                          handleRegenerate={handleRegenerate}
                          handleStopResponse={handleStopResponse}
                          updateMessage={updateMessage}
                          generateOneClickVideo={onVideoModalOpen}
                          continueMessage={continueMessage}
                          onShareModalOpen={onShareModalOpen}
                          selectedPrompt={selectedPrompt}
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "relative", zIndex: 2 }}
                    borderWidth={3}
                    borderColor={"yellow"}
                  >
                    {messages.slice(0, 3).map((message, index) => {
                      return (
                        <MessageRow
                          refid={sessionIdRef?.current}
                          //TODO: Check here if shared link is wrong
                          message={message}
                          config={config}
                          isSearchShow={isSearchShow}
                          iteration={index + 1}
                          isLast={index === messages.slice(0, 3).length - 1}
                          handleRegenerate={handleRegenerate}
                          handleStopResponse={handleStopResponse}
                          updateMessage={updateMessage}
                          generateOneClickVideo={onVideoModalOpen}
                          continueMessage={continueMessage}
                          selectedPrompt={selectedPrompt}
                        />
                      );
                    })}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                h="100%"
                overflow={"auto"}
                // maxHeight="690px"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "8px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `${secondaryColor}`,
                    borderRadius: "8px",
                    border: "4px solid rgba(0, 0, 0, 0.05)",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: `rgba(0, 0, 0, 0.4)`,
                  },
                  boxShadow: "0px 0px 3px -10px rgba(0, 0, 0, 0.2)",
                }}
                // columns={{ base: 1, md: 3 }}
                // gap="20px"
              >
                {!assistModal &&
                  config?.global?.PLAY_GROUND?.value === "YES" && (
                    <ChatPagePrompt2
                      promptGeneration={promptGeneration}
                      selectedPrompt={selectedPrompt}
                      // config={config}
                      setMicModal={handleMicModal}
                      // setMicModal={() => {
                      //   setMicModal(true)
                      //   setAuto(true)
                      // }}
                      // submitHandler={submitHandler}
                      selectedFilter={selectedFilter}
                      setVoiceModule={setVoiceModule}
                      setSelectedPrompt={setSelectedPrompt}
                      homepageModalClose={homepageModalClose}
                      onOpen={onOpen}
                      selectedVoice={selectedVoice}
                      setSelectedVoice={setSelectedVoice}
                    />
                  )}
              </Box>
            )}
          </Box>
        ) : (
          <div></div>
        )}

        {/* {queryvalueV !== "voice" && <> */}
        {true && (
          <>
            <FloatingMessageBar
              question={question}
              setQuestion={setQuestion}
              responseProgressRef={responseProgressRef}
              partiallyStoppedRef={partiallyStoppedRef}
              submitHandler={submitHandler}
              selectedPrompt={selectedPrompt}
              sessionIdRef={sessionIdRef}
              urlParser={urlParser}
              isTutorial={isTutorial}
              setShowModal={setShowModal}
              isPagiCall={isPagiCall}
              handleStopResponse={handleStopResponse}
              handleRegenerate={handleRegenerate}
              assistModal={assistModal}
              inputPlaceholder={inputPlaceholder}
              currentSessionInfo={currentSessionInfo}
              setAssistModal={setAssistModal}
              micModal={micModal}
              setMicModal={setMicModal}
              recording={recording}
              setRecording={setRecording}
              auto={auto}
              setAuto={setAuto}
              setFileUploads={setFileUploads}
              fileUploads={fileUploads}
            />{" "}
          </>
        )}

        {showModal &&
          queryvalueV !== "voice" &&
          config?.global?.PLAY_GROUND?.value === "YES" && (
            <FirstModal setHomepageModalClose={setHomepageModalClose} />
          )}

        {assistModal?._id && (
          <AssistantModal
            modalData={assistModal}
            modalCloseHandler={() => setAssistModal(null)}
            submitHandler={submitHandler}
            messages={messages}
            responseProgressRef={responseProgressRef.current}
            currentMessage={currentMessage}
            setCurrentMessage={setCurrentMessage}
            currentSessionInfo={currentSessionInfo}
            sessionChanged={sessionChanged}
            setCurrentSessionInfo={setCurrentSessionInfo}
            sessionIdRef={sessionIdRef}
            // closeModal={() => setModal(false)}
          />
        )}
      </Flex>
    </Flex>
  );
}
